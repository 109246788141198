
function setCurrent(imgElem: HTMLElement) {
  imgElem.setAttribute("current", "current");
  imgElem.style.display = null;
}

function resetCurrent(imgElem: HTMLElement) {
  imgElem.removeAttribute("current");
  imgElem.style.display = "none";
}

function findCurrentIndex(imgs: NodeList): number {
  for (let i = 0; i < imgs.length; ++i) {
    if ((imgs[i] as HTMLElement).hasAttribute("current")) {
      return i;
    }
  }
  return -1;
}

class ConstDavosGallery extends HTMLElement {
  private prev: HTMLElement;
  private next: HTMLElement;
  connectedCallback() {
    this.prev = document.createElement('div');
    this.prev.classList.add('ctrl');
    this.prev.classList.add('prev');
    this.prev.innerHTML = '&lt;';
    this.next = document.createElement('div');
    this.next.classList.add('ctrl');
    this.next.classList.add('next');
    this.next.innerHTML = '%gt;';
    this.insertBefore(this.prev, this.firstChild);
    this.appendChild(this.next);
    let imgs = this.querySelectorAll('const-davos-gallery-img');
    let hasCurrent = false;
    for (let i = 0; i < imgs.length; ++i) {
      let imgElem = imgs[i] as HTMLElement;
      if (!hasCurrent && imgElem.hasAttribute("current")) {
        hasCurrent = true;
        continue;
      }
      resetCurrent(imgElem);
    }
    if (!hasCurrent && imgs.length) {
      let imgElem = (imgs[0] as HTMLElement);
      setCurrent(imgElem)
    }
    this.querySelector(".ctrl.prev").addEventListener("click", (ev) => { this.onPrev(ev); });
    this.querySelector(".ctrl.next").addEventListener("click", (ev) => { this.onNext(ev); });
    this.addEventListener('swipe-left', (ev) => this.onPrev(ev));
    this.addEventListener('swipe-right', (ev) => this.onNext(ev));
  }
  private onPrev(ev: Event) {
    let imgs = this.querySelectorAll("const-davos-gallery-img");
    if (!imgs.length) {
      return;
    }
    let currentIndex = findCurrentIndex(imgs);
    let prevIndex = (currentIndex == -1) ? 0 : ((currentIndex - 1 + imgs.length) % imgs.length);
    if (currentIndex == prevIndex) {
      return;
    }
    setCurrent(imgs[prevIndex] as HTMLElement);
    if (currentIndex != -1) {
      resetCurrent(imgs[currentIndex] as HTMLElement);
    }
    ev.stopPropagation();
    ev.preventDefault();
  }
  private onNext(ev: Event) {
    let imgs = this.querySelectorAll("const-davos-gallery-img");
    if (!imgs.length) {
      return;
    }
    let currentIndex = findCurrentIndex(imgs);
    let nextIndex = (currentIndex + 1 + imgs.length) % imgs.length;
    if (currentIndex == nextIndex) {
      return;
    }
    setCurrent(imgs[nextIndex] as HTMLElement);
    if (currentIndex != -1) {
      resetCurrent(imgs[currentIndex] as HTMLElement);
    }
    ev.stopPropagation();
    ev.preventDefault();
  }
};

class ConstDavosGalleryImg extends HTMLElement {
  constructor() {
    super();
  }
  img;
  pointerDownListener = (ev) => this.onPointerDown(ev);
  pointerMoveListener = (ev) => this.onPointerMove(ev);
  pointerUpListener = (ev) => this.onPointerUp(ev);
  pointerOutListener = (ev) => this.onPointerOut(ev);
  connectedCallback() {
    this.img = this.querySelector('img');
    this.img.addEventListener('pointerdown', this.pointerDownListener);
    this.addEventListener('pointermove', this.pointerMoveListener);
    this.addEventListener('pointerup', this.pointerUpListener);
    this.addEventListener('pointerout', this.pointerOutListener);
  }
  disconnectedCallback() {
    this.img.removeEventListener('pointerdown', this.pointerDownListener);
    this.removeEventListener('pointermove', this.pointerMoveListener);
    this.removeEventListener('pointerup', this.pointerUpListener);
    this.removeEventListener('pointerout', this.pointerOutListener);
  }
  pointerDownX = undefined;
  onPointerDown(ev: PointerEvent) {
    if (ev.ctrlKey || ev.altKey || ev.metaKey || ev.shiftKey) {
      return;
    }
    this.pointerDownX = ev.clientX;
  }
  onPointerMove(ev: PointerEvent) {
  }
  onPointerUp(ev: PointerEvent) {
    if (this.pointerDownX == null) {
      return;
    }
    const delta = ev.clientX - this.pointerDownX;
    this.pointerDownX = undefined;
    if (Math.abs(delta) < 20) {
      return;
    }
    if (delta < 0) {
      this.dispatchEvent(new Event('swipe-left', { bubbles: true }))
    } else {
      this.dispatchEvent(new Event('swipe-right', { bubbles: true }))
    }
  }
  onPointerOut(ev: PointerEvent) {
  }
};

customElements.define("const-davos-gallery", ConstDavosGallery);
customElements.define("const-davos-gallery-img", ConstDavosGalleryImg);
